import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/clients/LAMI Logo.png";
// import ClientLogo02 from "../../assets/img/clients/hashtagbar_logo.jpg";
import ClientLogo03 from "../../assets/img/clients/Globe_Vector.svg";
import ClientLogo04 from "../../assets/img/clients/Logo.png";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo01} alt="lami logo" />
        </LogoWrapper>
        {/* <LogoWrapper className="flexCenter">
          <ImgStyle
            src={ClientLogo02}
            alt="hastagbar logo"
            style={{ borderRadius: "50%" }}
          />
        </LogoWrapper> */}
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo03} alt="global appartments logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo04} alt="cafe by ben logo" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  height: 100%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 128px;
  height: 128px;
  object-fit: contain;
  margin: auto;
`;
