import styled from "styled-components";

export default function Contact() {
  return (
    <Wrapper id="contact">
      {/* <div className="lightBg"> */}
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{ paddingBottom: "5px" }}>
              Schreibe uns noch Heute!
            </h1>
            <p className="font16">
              Über weitere Werbemedien informieren wir Sie gerne in einer
              persönlichen Beratung.
              <br />
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form action="https://formspree.io/f/xleqqzjn" method="POST">
                <label className="font13">Dein Name:</label>
                <input type="text" id="name" name="name" className="font20" />
                <label type="text" id="email" name="email" className="font13">
                  Deine Geschäfts-Email:
                </label>
                <input type="email" name="email" className="font20" />
                <label className="font13">Deine Nachricht:</label>
                <textarea
                  className="font20"
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                ></textarea>
                {/* <!-- your other form fields go here --> */}

                <SumbitWrapper className="flex">
                  <ButtonInput
                    type="submit"
                    className="pointer animate radius8"
                    style={{ maxWidth: "220px" }}
                  >
                    Nachricht senden
                  </ButtonInput>
                </SumbitWrapper>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.button`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
// const ContactImgBox = styled.div`
//   max-width: 180px;
//   align-self: flex-end;
//   margin: 10px 30px 10px 0;
// `;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
