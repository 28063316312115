import styled from "styled-components";

// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";

// Assets
import BrandExample1 from "../../assets/img/add/Website Muster.jpg";
import BrandExample2 from "../../assets/img/add/Insta Feed.png";
import BrandExample3 from "../../assets/img/add/Logo Streetbrand Svg.png";
import BrandExample4 from "../../assets/img/add/KI Model Bild.png";

import IconGrafik from "../Elements/Icons/Grafik.svg";
import IconKI from "../Elements/Icons/KI.svg";
import IconSocialMedia from "../Elements/Icons/SocialMedia.svg";
import IconWerbeartikel from "../Elements/Icons/Werbeartikel.svg";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="whiteBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">Corporate Identity</h4>
                <h2
                  className="font40 extraBold"
                  style={{ paddingBottom: "5px" }}
                >
                  Entwickel deine Marke
                </h2>
                <p className="font14">
                  Wir helfen Unternehmen, eine starke Corporate Identity zu
                  schaffen, die Ihre Werte und Visionen widerspiegelt.
                  Profitieren Sie von einem einheitlichen Markenauftritt, der
                  Vertrauen schafft und Kundenbindung fördert. Gemeinsam machen
                  wir Ihre Marke unverwechselbar und steigern Ihren Erfolg
                  nachhaltig.
                </p>

                <div style={{ width: "190px", margin: "30px 0 30px 0" }}>
                  <FullButton
                    title="Kontakt"
                    isLink
                    href={"contact"}
                    action={() => {
                      return;
                    }}
                    border
                  />
                </div>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={BrandExample1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={BrandExample2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img
                        src={BrandExample3}
                        alt="office"
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={BrandExample4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
        <div className="lightBg" style={{ padding: "60px 0" }}>
          <div className="container">
            <HeaderInfo>
              <h1
                className="font40 extraBold"
                style={{ paddingBottom: "10px" }}
              >
                Über uns
              </h1>
              <HeaderText
                className="font16"
                style={{
                  width: "475px",
                  // margin: "0 auto",
                  // padding: "0 10px",
                  boxSizing: "border-box",
                }}
              >
                Bei Werbeffekt steht Ihr Erfolg im Mittelpunkt. Als Ihre
                Full-Service-Werbeagentur bieten wir Ihnen maßgeschneiderte
                Lösungen im Branding und Social Media Marketing. Wir helfen
                Ihnen eine unverwechselbare Markenidentität zu entwickeln und
                setzen messbar wirkungsvolle Social Media Kampagnen um.
                <br />
              </HeaderText>
            </HeaderInfo>
            <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon={IconGrafik}
                  title="Grafikdesign"
                  subtitle={
                    "Unser Grafikdesign-Team verwandelt Ihre Vision in visuelle Meisterwerke. Von Logos über Broschüren bis hin zu Werbebannern – wir kreieren Designs, die Ihre Marke unverwechselbar machen und Ihre Botschaft klar und ansprechend vermitteln."
                  }
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon={IconSocialMedia}
                  title="Social Media"
                  subtitle={
                    "Maximieren Sie Ihre Online-Präsenz mit unseren maßgeschneiderten Social-Media-Strategien. Wir entwickeln und verwalten Inhalte, die Ihre Zielgruppe ansprechen, Interaktionen fördern und Ihre Marke auf allen relevanten Plattformen stärken."
                  }
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon={IconWerbeartikel}
                  title="Werbeartikel"
                  subtitle={
                    "Hinterlassen Sie einen bleibenden Eindruck mit unseren kreativen Werbeartikeln. Von hochwertigen Gadgets bis hin zu maßgeschneiderten Giveaways – wir sorgen dafür, dass Ihre Marke bei Ihren Kunden und Partnern im Gedächtnis bleibt."
                  }
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon={IconKI}
                  title="KI"
                  subtitle={
                    "Erreichen Sie Ihre Marketingziele schneller und effizienter mit präzisen Einblicken in das Kundenverhalten. Optimierte Strategien führen zu höherer Kundenzufriedenheit und besseren Ergebnissen."
                  }
                />
              </ServiceBoxWrapper>
            </ServiceBoxRow>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0 0 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const HeaderText = styled.p`
  width: 475px;
  text-align: left;
  @media (max-width: 860px) {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    h2 {
      // text-align: center;
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    // border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
