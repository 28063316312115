import { Section } from "./Datenschutz";

export default function Impressum() {
  return (
    <Section id="impressum">
      <h1>Impressum</h1>
      <p>Angaben gemäß § 5 TMG</p>
      <p>Werbeffekt</p>
      <p>Finn Höfert, Colin Lunis Baumunk</p>
      <p>Pestalozzistraße 10</p>
      <p>27568 Bremerhaven</p>
      <h1
        style={{
          marginTop: "1em",
        }}
      >
        Kontakt
      </h1>

      <p>
        Telefon:
        <span>
          <a href="tel:+4915210274922" style={{ marginLeft: "0.5em" }}>
            +49 1521 0274922
          </a>
        </span>
      </p>
      <p>
        Email:{" "}
        <span>
          <a href="mailto:info@werbeffekt.de" style={{ marginLeft: "0.5em" }}>
            info@werbeffekt.de
          </a>
        </span>
      </p>
    </Section>
  );
}
